(function () {

	angular.module('Plania').config([
		'$provide', function ($provide) {

			$provide.decorator('CommonService', [
				'$delegate', '$localStorage', function ($delegate, $localStorage) {
					var service = $delegate;

					service.disableWebMenuNavigation = function () {
						return service.getFilterData().selectedEstate.Guid.length === 0;
					};

					return service;
				}]);
		}
	]);

})();
