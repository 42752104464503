(function () {
	angular.module('Plania').factory('svvTunnelRepository', ['$http', 'config', 'authService', 'growlService', '$cacheFactory', '$q', svvTunnelRepository]);

	function svvTunnelRepository($http, config, authService, growlService, $cacheFactory, $q) {
		var cache = $cacheFactory('svvTunnelRepositoryHttpOnce');

		var httpConfig = function (relativeUrl, method, data) {
			return authService.refreshAuthentication().then(function (result) {
				return {
					method: method,
					url: encodeURI(config.baseUrl + 'svvtunnel/' + relativeUrl),
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
					},
					data: jQuery.param(data)
				};
			});
		};

		var getCall = function (relativeUrl) {
			var deferred = $q.defer();
			handleGet(relativeUrl)
				.then(function (apiResponse) {
					var result = apiResponse;

					if (apiResponse.status === 200) {
						deferred.resolve(result);
					} else {
						deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
					}
				}, function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		};

		function handleGet(relativeUrl) {
			return httpConfig(relativeUrl, 'GET', {}).then(function (result) {
				var promise = cache.get(relativeUrl);
				if (promise && promise.$$state.status === 0) {
					// return any pending promises for the same URL
					return promise;
				}
				return cache.put(relativeUrl, $http(result));
			});
		};

		function postCall(relativeUrl, data) {
			var deferred = $q.defer();
			handlePost(relativeUrl, data)
				.then(function (apiResponse) {
					var result = apiResponse;

					if (apiResponse.status === 200) {
						deferred.resolve(result);
					} else {
						deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
					}
				}, function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		};

		function handlePost(relativeUrl, data) {
			return httpConfig(relativeUrl, 'POST', data)
				.then(function (result) {
					return $http(result);
				});
		};

		function putCall(relativeUrl, data) {
			var deferred = $q.defer();
			handlePut(relativeUrl, data)
				.then(function (apiResponse) {
					var result = apiResponse;

					if (apiResponse.status === 200) {
						deferred.resolve(result);
					} else {
						deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
					}
				}, function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		};

		function handlePut (relativeUrl, data) {
			return httpConfig(relativeUrl, 'PUT', data)
				.then(function (result) {
					return $http(result);
				});
		};

		function deleteCall(relativeUrl, data) {
			var deferred = $q.defer();
			handleDelete(relativeUrl, data)
				.then(function (apiResponse) {
					var result = apiResponse;

					if (apiResponse.status === 200) {
						deferred.resolve(result);
					} else {
						deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
					}
				}, function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		};

		function handleDelete (relativeUrl, data) {
			return httpConfig(relativeUrl, 'DELETE', data)
				.then(function (result) {
					return $http(result);
				});
		};

		var growl = function (message, type) {
			growlService.growl(message, type, { enter: 'animated fadeInRight', exit: 'animated fadeOutRight' });
		}

		function getStringFromErrorObject(error) {
			if (error && typeof error === 'object') {
				if (error.message && error.message !== "") {
					return error.message;
				}
				if (error.data && typeof error.data === 'string') {
					return error.data;
				}
				if (error.data && Array.isArray(error.data)) {
					return error.data;
				}
				if (error.data.message && typeof error.data.message === 'string')
					return error.data.message;
			}
			return error;
		};

		function getErrorMessageFromStatus(status, error) {
			var message = getStringFromErrorObject(error);
			if (typeof message === 'string' && message.length > 0) {
				return message;
			}

			if (Array.isArray(message)) {
				return message;
			}

			switch (status) {
				case 401:
					return authService.getUserData().isAuthenticated ? "Mangler tilgang" : "Du må logge inn for å se denne informasjonen";
				case 404:
					return "Ingen data funnet";
				case 409:
					return "Konflikt i Api";
				default:
					return "Ukjent feil oppstod. Http status kode: " + status;
			}
		};


		return {
			get: getCall,
			post: postCall,
			put: putCall,
			delete: deleteCall,
			growl: growl
		};
	}
})();
