(function () {
	angular.module('Plania').controller('NvdbChangeSetListController', ['$scope', 'svvTunnelRepository', 'ngTableParams', '$modal', 'Repository',  controller]);

	function controller($scope, svvTunnelRepository, ngTableParams, $modal, repository) {
		var nvdbApiUrl = 'NvdbChangeSet';

		$scope.changeSetTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { Guid: 'asc' },
				filter: { searchString: '' }
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {

					var url = nvdbApiUrl +
						'?page=' +
						(params.page() - 1) +
						'&pageSize=' +
						params.count() +
						'&search=' +
						params.filter().searchString +
						'&dataOwner=' + repository.commonService.getFilterData().selectedDataOwner.Guid;

					svvTunnelRepository.get(url, {}).then(function (response) {
							var result = response.data;
							$scope.changeSetTable.settings().total = result.TotalCount;
							$defer.resolve(result.List);
						},
						function (error) {
							svvTunnelRepository.growl(error, 'danger');
						});
				}
			});

		$scope.openChangeSetModal = function (changeSet) {
			$modal.open({
				templateUrl: 'customizations/10088/nvdbChangeSetModal.html',
				size: 'xl',
				controller: 'NvdbChangeSetModalController',
				resolve: {
					params: function () {
						return {
							nvdbId: changeSet.NvdbId,
							nvdbTypeId: changeSet.NvdbTypeId,
							selectedNvdbChangeSetId: changeSet.NvdbChangeSetId
						};
					}
				}
			}).result.then(function (result) {
			}, function () {
				//cancel
			});
		}

	}
})();
