(function () {
	angular.module('Plania').controller('nvdbEquipmentController', ['$scope', 'svvTunnelRepository', 'CommonService', 'ngTableParams', controller]);

	function controller($scope, svvTunnelRepository, commonService, ngTableParams) {
		$scope.nvdbEquipmentTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Guid: 'asc' },
			filter: { searchString: '' }
		},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					svvTunnelRepository.get('WeatherStation?GuidDataOwner=' + commonService.filterData.selectedDataOwner.Guid, {}).then(function (response) {
						$defer.resolve(response.data);
					}, function (error) {
						svvTunnelRepository.growl(error, 'danger');
					});
				}
			});

		$scope.createEquipment = function (row) {
			svvTunnelRepository.post('WeatherStation?GuidDataOwner=' + commonService.filterData.selectedDataOwner.Guid, row).then(function (response) {
				svvTunnelRepository.growl('Anlegg opprettet', 'success');
				$scope.nvdbEquipmentTable.reload();
			}, function (error) {
				svvTunnelRepository.growl(error, 'danger');
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			$scope.nvdbEquipmentTable.reload();
		});
	}
})();