(function () {
	angular.module('Plania').controller('NvdbChangeSetModalController', ['$scope', 'params', '$modalInstance', 'svvTunnelRepository', 'ngTableParams', 'SignalR', 'Repository', controller]);

	function controller($scope, params, $modalInstance, svvTunnelRepository, ngTableParams, signalR, repository) {
		$scope.model = {};
		$scope.model.nvdbId = params.nvdbId;
		$scope.model.nvdbTypeId = params.nvdbTypeId;
		$scope.selectedNvdbChangeSetId = params.selectedNvdbChangeSetId;

		$scope.tabs = [
			{
				title: 'Nytt endringssett',
				active: true,
				visible: true
			},
			{
				title: 'Endringssett',
				active: false,
				visible: true
			}
		];
		if ($scope.selectedNvdbChangeSetId) {
			$scope.tabs[1].active = true;
			$scope.tabs[0].visible= false;
		}

		$scope.changeSetProcessing = false;
		$scope.statusLog = [];
		$scope.showDetailsPrompt = false;
		$scope.firstTabVisible = true;
		$scope.showFormattedStatus = false;
		$scope.syncinfoviewmodel = null;
		$scope.dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
		$scope.includeDryRuns = false;

		svvTunnelRepository.get('nvdbcard/' + $scope.model.nvdbId, {}).then(function (result) {
			$scope.model.nvdbEntity = result.data;
			svvTunnelRepository.get('NvdbChangeSet/NvdbProperties/' + $scope.model.nvdbTypeId + '?dataowner=' + $scope.dataOwner, {}).then(function (propertiesResult){
				$scope.AllProperties = propertiesResult.data.Egenskapstyper;
				setAvailableProperties();
			}, function(propertiesError){
				svvTunnelRepository.growl(propertiesError.data.Message, 'danger');
			});
		}, function (error){
			svvTunnelRepository.growl(error.data.Message, 'danger');
		});

		svvTunnelRepository.get('nvdbcard/syncinfoviewmodel/' + $scope.model.nvdbId, {}).then(function (result) {
			$scope.syncinfoviewmodel = result.data;
		});

		function setAvailableProperties (){
			$scope.availableProperties = _.filter($scope.AllProperties, function(property){
				if (property.Navn !== 'Driftsmerking' && property.Navn !== 'Driftsmerking TFM, lokasjonskode') return false;
				if (property.Datatype === 'Liste') return false;
				var existingProperty = _.find($scope.model.nvdbEntity.Egenskaper, function (egenskap) {return egenskap.Navn === property.Navn;});
				if (existingProperty){
					property.Verdi = existingProperty.Verdi;
					property.OldVerdi = existingProperty.Verdi;
				}

				return true;
			});
		}

		function getChangeSetDto() {
			var changeSetPropertes = _.filter($scope.availableProperties, function(egenskap) {
				if (egenskap.Verdi !== egenskap.OldVerdi)
					return true;
			});

			var mappedProperties = _.map(changeSetPropertes, function(egenskap){
				return {
					NvdbPropertyId: egenskap.Id,
					NvdbPropertyLabel: egenskap.Navn,
					NewPropertyValue: egenskap.Verdi,
					OldPropertyValue: egenskap.OldVerdi
				};
			});

			var dto = {
				NvdbTypeId: $scope.model.nvdbEntity.Metadata.Type.Id,
				NvdbId: $scope.model.nvdbEntity.Id,
				Versjon: $scope.model.nvdbEntity.Metadata.Versjon,
				LastModifiedInNvdb: $scope.model.nvdbEntity.Metadata.Sist_Modifisert,
				ChangeSetProperties: mappedProperties
			};

			return dto;
		}

		$scope.validateChangeSet = function(){
			$scope.statusLog = [];

			var dto = getChangeSetDto();
			if (dto.ChangeSetProperties.length === 0)return;
			var changeSetText = '';
			_.forEach(dto.ChangeSetProperties, function(prop){
				if (prop.OldPropertyValue)
					changeSetText += prop.NvdbPropertyLabel + ' endres fra: ' + prop.OldPropertyValue + ' til: ' + prop.NewPropertyValue;
				else
					changeSetText += prop.NvdbPropertyLabel + ' legges til med verdi: ' + prop.NewPropertyValue;
			});
			swal({
					title: 'Endring vil bli prøvekjørt',
					text: 'Følgende endringer vil bli prøvekjørt til NVDB  \r\n ' + changeSetText,
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#337ab7",
					confirmButtonText: 'Bekreft',
					cancelButtonText: 'Avbryt',
					closeOnConfirm: true
				},
				function() {
					window.onkeydown = null;
					window.onfocus = null;

					svvTunnelRepository.post('NvdbChangeSet?isDryRun=true' + '&dataowner=' + $scope.dataOwner, dto).then(function (result){
						$scope.includeDryRuns = true;
					}, function (error){
						svvTunnelRepository.growl(error.data.Message, 'danger');
					});
				});
		};

		$scope.executeChangeSet = function(){
			$scope.statusLog = [];

			var dto = getChangeSetDto();
			if (dto.ChangeSetProperties.length === 0)return;
			var changeSetText = '';
			_.forEach(dto.ChangeSetProperties, function(prop){
				changeSetText += prop.NvdbPropertyLabel + ' endres fra ' + prop.OldPropertyValue + ' til ' + prop.NewPropertyValue;
			});

			swal({
					title: 'Endring vil bli sendt',
					text: 'Følgende endringer vil bli sendt til NVDB  \r\n ' + changeSetText,
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#337ab7",
					confirmButtonText: 'Bekreft',
					cancelButtonText: 'Avbryt',
					closeOnConfirm: true
				},
				function() {
					window.onkeydown = null;
					window.onfocus = null;

					svvTunnelRepository.post('NvdbChangeSet?isDryRun=false' + '&dataowner=' + $scope.dataOwner, dto).then(function (result){

					}, function (error){
						svvTunnelRepository.growl(error.data.Message, 'danger');
					});
				});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.selectRow = function(row){
			$scope.selectedRow = row;
			if ($scope.selectedRow.StatusJson)
				$scope.selectedRow.FormattedStatus = JSON.parse($scope.selectedRow.StatusJson);
			if ($scope.selectedRow.ChangesJson)
				$scope.selectedRow.FormattedChanges = JSON.parse($scope.selectedRow.ChangesJson);
		};

		initializeSignalR();
		function initializeSignalR() {
			var signalRMethodName = 'nvdbChangeSet_nvdbId_' +  $scope.model.nvdbId;

			signalR.on(signalRMethodName, function(progress) {
				if (progress.Finished === true){
					if (progress.Message)
						$scope.selectedNvdbChangeSetId = progress.Message;
					$scope.changeSetProcessing = false;
					$scope.showDetailsPrompt = true;
					$scope.changeSetTable.reload();
					return;
				}
				$scope.changeSetProcessing = true;
				$scope.statusLog.push(progress.Message);
			});

			$scope.$on('$destroy', function () {
				signalR.off(signalRMethodName);
			});
		}

		$scope.navigateToDetails = function (){
			$scope.firstTabVisible = false;
		}

		$scope.getStatus = function(changeSetId) {
			svvTunnelRepository.get('NvdbChangeSet' + '/GetStatus?changeSetId=' + changeSetId + '&dataowner=' + $scope.dataOwner, {}).then(function (response) {
					var result = response.data;
					$scope.changeSetTable.reload();
				},
				function (error) {
					svvTunnelRepository.growl(error, 'danger');
				});
		}

		$scope.onDryRunToggled = function (){
			$scope.includeDryRuns = !$scope.includeDryRuns;
			$scope.changeSetTable.reload();
		};

		$scope.changeSetTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { Guid: 'asc' },
				filter: { searchString: '' }
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var url = 'NvdbChangeSet' +
						'?page=' +
						(params.page() - 1) +
						'&pageSize=' +
						params.count() +
						'&search=' +
						params.filter().searchString +
						'&nvdbID=' + $scope.model.nvdbId +
						'&includeDryRuns=' + $scope.includeDryRuns +
						'&dataowner=' + $scope.dataOwner;
					svvTunnelRepository.get(url, {}).then(function (response) {
							var result = response.data;
							$scope.changeSetTable.settings().total = result.TotalCount;
							if ($scope.selectedNvdbChangeSetId)
							{
								var selectedRow = _.find(result.List, function(row){
									return row.NvdbChangeSetId === $scope.selectedNvdbChangeSetId;
								});
								if (selectedRow)
									$scope.selectRow(selectedRow);
							} else if ($scope.selectedRow){
								var selectedRow = _.find(result.List, function(row){
									return row.NvdbChangeSetId === $scope.selectRow.NvdbChangeSetId;
								});
								if (selectedRow)
									$scope.selectRow(selectedRow);
							}
							$defer.resolve(result.List);
						},
						function (error) {
							svvTunnelRepository.growl(error, 'danger');
						});
				}
			});
	}
})();
