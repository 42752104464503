(function() {
	var app = angular.module('Plania');

	app.config([
		'$stateProvider', function(stateProvider) {

			stateProvider.state({
				name: 'SyncInformation',
				url: '/syncInformation',
				templateUrl: 'customizations/10088/syncInformation.html',
				controller: 'syncInformationController',
				backNavigation: true
			});

			stateProvider.state({
				name: 'nvdbEquipment',
				url: '/nvdbEquipment',
				templateUrl: 'customizations/10088/nvdbEquipment.html',
				controller: 'nvdbEquipmentController',
				backNavigation: true
			});

			stateProvider.state({
				name: 'propertyMapping',
				url: '/propertyMapping',
				templateUrl: 'customizations/10088/propertyMapping.html',
				controller: 'propertyMappingController',
				backNavigation: true
			});

			stateProvider.state({
				name: 'changeSetList',
				url: '/changeSetList',
				templateUrl: 'customizations/10088/nvdbChangeSetList.html',
				controller: 'NvdbChangeSetListController',
				backNavigation: true
			});
		}
	]);
})();
