(function () {

	var app = angular.module('Plania');

	app.directive('nvdbInformation', function () {
		return {
			restrict: 'E',
			scope: {
				nvdbId: '=nvdbId',
				objectType: '=objectType'
			},
			controller: ['$scope', 'svvTunnelRepository', '$modal', controller],
			templateUrl: 'customizations/10088/nvdbInformation.html'
		};
	});

	function controller(scope, svvTunnelRepository, $modal) {
		scope.model = {};

		function fetchFromNvdb() {
			svvTunnelRepository.get('nvdbcard/' + scope.nvdbId, {}).then(function (result) {
				if (result.status === 200) {
					scope.model.nvdbEntity = result.data;
					scope.nvdbTypeId = result.data.Metadata.Type.Id;
				} else {
					scope.errorMessage = result.data.message;
				}
			});

			svvTunnelRepository.get('nvdbcard/syncinfo/' + scope.nvdbId, {}).then(function (result) {
				scope.model.syncInformation = result.data;
			});
		}

		if (scope.nvdbId) {
			fetchFromNvdb();
		}

		scope.$watch('nvdbId', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			if (!newValue) return;

			fetchFromNvdb();
		});

		scope.synchronizeEnitity = function () {
			var syncAction = scope.objectType;
			var url = 'nvdbcard/sync/' + scope.nvdbId + '/' + scope.nvdbTypeId + '/' + syncAction;

			svvTunnelRepository.put(url, {}).then(function(result) {
				svvTunnelRepository.growl('Synkronisering fullført, oppdater siden for å hente nyeste data', 'success');
			});
		};

		scope.synchronizeChildren = function () {
			var syncAction = 'EquipmentHierarchy';
			var url = 'nvdbcard/sync/' + scope.nvdbId + '/' + scope.nvdbTypeId + '/' + syncAction;
			swal({
				title: 'Synkronisering av anleggshierarki',
				text: 'Synkronisering kan ta lang tid og vil bli utført i bakgrunnen, se logg for status',
				type: 'warning',
				showCancelButton: true,
				closeOnConfirm: false
			}, function () {
				svvTunnelRepository.put(url, {}).then(function (result) {
					swal('Synkronisering startet', '', "success");
				});
			});
		};

		scope.openChangeSetModal = function() {
			$modal.open({
				templateUrl: 'customizations/10088/nvdbChangeSetModal.html',
				size: 'xl',
				controller: 'NvdbChangeSetModalController',
				resolve: {
					params: function () {
						return {
							nvdbId: scope.model.nvdbEntity.Id,
							nvdbTypeId: scope.model.nvdbEntity.Metadata.Type.Id
						};
					}
				}
			}).result.then(function (result) {
			}, function () {
				//cancel
			});
		};
	}
})();
