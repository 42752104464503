(function () {

    angular.module('Plania').config([
        '$provide', function ($provide) {

            $provide.decorator('AutoCompleteService', [
                '$delegate', function ($delegate, $localStorage) {
                    var service = $delegate;

					service.equipments = function (searchParam, page, pageSize, sorting) {
						if (!sorting || sorting.length === 0)
							sorting = { ID: 'asc' };
						var columns = ['Id', 'Description', 'Text20', 'Area.Id', 'Area.Description', 'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid'];
						return service.getAutoComplete('equipment/', searchParam, page, pageSize, sorting, function (r) {
							return service.getModel(r.Guid, r.Id, r.Description, r);
						}, columns);
					};

                    return service;
                }]);
        }
    ]);

})();
