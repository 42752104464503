(function () {
	angular.module('Plania').controller('NvdbEquipmentLookupController', ['$scope', 'params', '$modalInstance', 'svvTunnelRepository', 'ngTableParams', '$filter', controller]);

	function controller($scope, params, $modalInstance, svvTunnelRepository, ngTableParams, $filter) {
		$scope.model = {
			GuidBuilding: params.guidBuilding,
			GuidEquipment: params.guidEquipment,
			NvdbObjekt: null
		};

		$scope.equipmentLookup = {};
		$scope.lookupResultTable = null;
		$scope.isTableView = true;

		$scope.search = { term: '' };

		$scope.getLookupResults = function() {
			var url = 'nvdbEquipmentLookup/?guidBuilding=' + $scope.model.GuidBuilding +
				'&guidEquipment=' + $scope.model.GuidEquipment;

			svvTunnelRepository.get(url, {}).then(
				function (response) {
					$scope.equipmentLookup = response.data;
					var data = $scope.equipmentLookup.LookupResults;
					$scope.lookupResultTable = new ngTableParams({
						page: 1,
						count: 10,
						filter: $scope.search
					},
						{
						total: data.length,
						getData: function ($defer, params) {
							var filteredData = params.filter().term ?
								$filter('filter')(data, params.filter().term)
								: data;

							params.total(filteredData.length);
							$defer.resolve(filteredData.slice((params.page() - 1)
								* params.count(), params.page() * params.count()));
						} 
					});
				},
				function (error) {
					svvTunnelRepository.growl(error, 'danger');
				}
			);
		}

		$scope.selectRow = function(lookupResultRow) {
			$scope.selectedLookupResult = _.find($scope.equipmentLookup.LookupResults, function(lookupResult) {
				return lookupResultRow.NvdbObjekt.Id === lookupResult.NvdbObjekt.Id;
			});
		};

		$scope.isSelected = function (id) {
			if (!$scope.selectedLookupResult) return false;
			return $scope.selectedLookupResult.NvdbObjekt.Id === id;
		};

		$scope.getProperty = function (propertyName, nvdbObjekt) {
			var property = _.find(nvdbObjekt.Egenskaper, { "Navn": propertyName });
			if (property) return property.Verdi;
			return "";
		};

		$scope.toggleDetails = function() {
			$scope.isTableView = !$scope.isTableView;
			$scope.isDetailsView = !$scope.isDetailsView;
		};

		$scope.canSave = function() {
			if ($scope.selectedLookupResult) return true;
		};

		$scope.saveAction = function () {
			$scope.model.NvdbObjekt = $scope.selectedLookupResult.NvdbObjekt;

			svvTunnelRepository.post('nvdbEquipmentLookup', $scope.model).then(
				function (response) {
					$modalInstance.close(response.data);
				},
				function(error) {
					svvTunnelRepository.growl(error, 'danger');
				}
			);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}
})();
