(function () {
	angular.module('Plania').controller('addPropertyMappingController', ['$scope', '$modalInstance', 'params', 'svvTunnelRepository', controller]);

	function controller($scope, $modalInstance, params, svvTunnelRepository) {
		$scope.category = params.Category;
		$scope.objectType = params.ObjectType;

		$scope.$watch('objectType.NvdbTypeId', function (newValue, oldValue) {
			if (newValue) {
				var url = 'propertymappingtypes/availablePlaniaProperties/' +
					$scope.category.prefix +
					'/' +
					$scope.objectType.PlaniaGuid +
					'/' +
					$scope.objectType.NvdbTypeId;
				svvTunnelRepository.get(url, {}).then(
					function (response) {
						$scope.planiaProperties = response.data;
					},
					function (error) {
						svvTunnelRepository.growl(error, 'danger');
					}
				);
			}
		});

		$scope.onPlaniaPropertyChanged = function () {
			var url = 'propertymappingtypes/nvdbEgenskaper/' +
				$scope.objectType.NvdbTypeId +
				'/' +
				$scope.selectedPlaniaProperty.PlaniaProperty;

			svvTunnelRepository.get(url, {}).then(
				function (response) {
					$scope.nvdbProperties = response.data;
				}, function (error) {
					svvTunnelRepository.growl(error, 'danger');
				});
		};

		$scope.canSave = function () {
			if ($scope.selectedNvdbProperty && $scope.selectedPlaniaProperty) {
				return true;
			}
			return false;
		};

		$scope.save = function () {
			var model = {
				CategoryPrefix: $scope.category.prefix,
				CategoryGuid: $scope.objectType.PlaniaGuid,
				PlaniaProperty: $scope.selectedPlaniaProperty.PlaniaProperty,
				PlaniaDynamicLabelProperty: $scope.selectedPlaniaProperty.PlaniaDynamicLabelName,
				PlaniaDynamicLabelValue: $scope.selectedPlaniaProperty.PlaniaDynamicLabelValue,
				NvdbTypeId: $scope.objectType.NvdbTypeId,
				NvdbPropertyTypeId: $scope.selectedNvdbProperty.NvdbPropertyId,
				NvdbLabel: $scope.selectedNvdbProperty.NvdbLabel,
				IsTemplate: $scope.selectedPlaniaProperty.IsTemplate
			};
			svvTunnelRepository.post('PropertyMapping', model).then(function (response) {
				$modalInstance.close('saved');
			}, function (error) {
				svvTunnelRepository.growl(error, 'danger');
			});
		};

		$scope.cancel = function() {
			$modalInstance.dismiss('canceled');
		};
	}
})();