(function () {
	angular.module('Plania').controller('syncInformationController', ['$scope', 'svvTunnelRepository', 'ngTableParams', controller]);

	function controller($scope, svvTunnelRepository, ngTableParams) {
		var nvdbApiUrl = 'SyncInformation';

		$scope.nvdbTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Guid: 'asc' },
			filter: { searchString: '' }
		},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {

					var url = nvdbApiUrl +
						'?page=' +
						(params.page() - 1) +
						'&pageSize=' +
						params.count() +
						'&search=' +
						params.filter().searchString +
						'&action=syncInformations';

					svvTunnelRepository.get(url, {}).then(function (response) {
						var result = response.data;
						$scope.nvdbTable.settings().total = result.TotalCount;
						$defer.resolve(result.List);
					},
						function (error) {
							svvTunnelRepository.growl(error, 'danger');
						});
				}
			});


	}
})();