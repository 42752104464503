(function () {
	angular.module('Plania').controller('propertyMappingController', ['$scope', 'svvTunnelRepository', 'CommonService', 'ngTableParams', '$modal', controller]);

	function controller($scope, svvTunnelRepository, commonService, ngTableParams, $modal) {

		$scope.categories = [
			{ prefix: 'Estate', title: 'Eiendommer' },
			{ prefix: 'Building', title: 'Bygninger' },
			{ prefix: 'Equipment', title: 'Anlegg' }
		];

		$scope.onCategorySelected = function () {
			svvTunnelRepository.get('propertymappingtypes/' + $scope.selectedCategory.prefix, {}).then(
				function(response) {
					if (response.data) {
						$scope.objectTypes = response.data;
						$scope.objectTypes.unshift({ ObjectTypeName: 'Alle', PlaniaGuid: null });
					}
				});
		};

		$scope.nvdbPropertyMappingTable = null;

		$scope.onObjectTypeSelected = function () {
			if ($scope.selectedObjectType === null) return;

			if ($scope.nvdbPropertyMappingTable) {
				$scope.nvdbPropertyMappingTable.reload();
			} else {
				$scope.nvdbPropertyMappingTable = new ngTableParams({
						sorting: { Category: 'asc' },
						filter: { searchString: '' }
					},
					{
						getData: function ($defer, params) {

							var url = 'PropertyMapping' +
								'?CategoryPrefix=' +
								$scope.selectedCategory.prefix;

							if ($scope.selectedObjectType.PlaniaGuid) {
								url = url + '&CategoryPlaniaGuid=' + $scope.selectedObjectType.PlaniaGuid;
							}

							if ($scope.showOnlyDifferent === true) {
								url = url + '&OnlyDifferent=' + $scope.showOnlyDifferent;
							}

							svvTunnelRepository.get(url, {}).then(function (response) {
									$defer.resolve(response.data);
								},
								function (error) {
									svvTunnelRepository.growl(error, 'danger');
								});
						}
					}
				);
			}
		};

		$scope.addNewMappingdisabled = function () {
			if ($scope.selectedObjectType && $scope.selectedObjectType.ObjectTypeName !== 'Alle') {
				return false;
			}
			return true;
		}

		$scope.onShowOnlyDifferentSelected = function () {
			if ($scope.nvdbPropertyMappingTable) {
				$scope.nvdbPropertyMappingTable.reload();
			}
		};

		$scope.updateMapping = function (row) {
			svvTunnelRepository.put('PropertyMapping?action=fixlabel', row).then(function (response) {
				$scope.nvdbPropertyMappingTable.reload();
				svvTunnelRepository.growl('Egenskapsmapping oppdatert', 'success');
			}, function (error) {
				svvTunnelRepository.growl(error, 'danger');
			});
		};

		$scope.disableMapping = function (row) {
			svvTunnelRepository.put('PropertyMapping?action=disable', row).then(function(response) {
				$scope.nvdbPropertyMappingTable.reload();
				svvTunnelRepository.growl('Egenskapsmapping oppdatert', 'success');
			}, function (error) {
				svvTunnelRepository.growl(error, 'danger');
			});
		};

		$scope.deleteMapping = function (row) {
			svvTunnelRepository.delete('PropertyMapping', row).then(function(response) {
				$scope.nvdbPropertyMappingTable.reload();
				svvTunnelRepository.growl('Egenskapsmapping slettet', 'success');
			}, function(error) {
				svvTunnelRepository.growl(error, 'danger');
			});
		};

		$scope.addNewMapping = function () {
			$modal.open({
				templateUrl: 'customizations/10088/addPropertyMappingModal.html',
				controller: 'addPropertyMappingController',
				resolve: {
					params: function() {
						return {
							Category: $scope.selectedCategory,
							ObjectType: $scope.selectedObjectType
						};
					}
				}
			}).result.then(function (result) {
				if (result === 'saved') {
					$scope.nvdbPropertyMappingTable.reload();
				}
			});
		};
	}
})();